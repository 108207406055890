import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/basti/Documents/projects/linktree/src/components/layout.js";
import Seo from "../components/seo";
import Link from "../components/link";
import "./index.scss";
import Instagram from "../../svg-icons/instagram.svg";
import Twitter from "../../svg-icons/twitter.svg";
import Github from "../../svg-icons/github.svg";
import Gitlab from "../../svg-icons/gitlab.svg";
import Soundcloud from "../../svg-icons/soundcloud.svg";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Seo title="Linktree - Basti Paintner" mdxType="Seo" />
    <div className="linktree">
      <h2>{`Welcome to my Linktree.`}</h2>
      <br />
      <Link to="https://instagram.com/bastivegan" mdxType="Link"><Instagram className="svg" mdxType="Instagram" />Instagram</Link>
      <Link to="https://twitter.com/lItc0de" mdxType="Link"><Twitter className="svg" mdxType="Twitter" />Twitter</Link>
      <Link to="https://github.com/litc0de" mdxType="Link"><Github className="svg" mdxType="Github" />Github</Link>
      <Link to="https://gitlab.com/litc0de" mdxType="Link"><Gitlab className="svg" mdxType="Gitlab" />Gitlab</Link>
      <Link to="https://soundcloud.com/user-916182571" mdxType="Link"><Soundcloud className="svg" mdxType="Soundcloud" />Soundcloud</Link>
      <Link to="https://mendgenpeissl.com/about" mdxType="Link">Cool website I am working on (Architecture atelier in Berlin)</Link>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      